<template>
  <div class="balances">
    <div v-if="!isSupplier" class="actions-wrapper">
      <rcc-button
        color="success"
        :is-loading="isExcelUploadLoading"
        @click="handleExcelUploadClick"
      >
        Выгрузить в Excel
      </rcc-button>
    </div>

    <component :is="component" />
  </div>
</template>

<script>
import getXls from '@/utils/get-xls'

import RccButton from 'Components/controls/rcc-button'

const RccCommonBalances = () => import('./components/common.vue')
const RccCommonRccBalancesForSupplierRoleBalances = () => import('./components/supplier.vue')

export default {
  components: {
    RccButton
  },

  data() {
    return {
      isExcelUploadLoading: false
    }
  },

  computed: {
    isSupplier() {
      return this.$store.user.role === this.$constants.users.supplier
    },

    component() {
      return this.isSupplier ? RccCommonRccBalancesForSupplierRoleBalances : RccCommonBalances
    }
  },

  methods: {
    handleExcelUploadClick() {
      this.isExcelUploadLoading = true

      this.$apiMethods.suppliers.getExcel(this.filtersQuery)
        .then(response => {
          getXls.bind(this)(response)
        })
        .finally(() => { this.isExcelUploadLoading = false })
    }
  }
}
</script>

<style lang="scss" scoped>
.balances {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: $base-page-padding;

  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}
</style>
